var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.error.length > 0 ? _c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": true
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error obteniendo datos")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(_vm._s(_vm.error))])]) : _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('b-card-actions', {
    ref: "refreshCard",
    attrs: {
      "no-body": "",
      "noActions": ""
    }
  }, [_c('b-card-body', [_c('h2', {
    staticClass: "mb-2"
  }, [_vm._v("Crear Easy Créditos")]), _c('b-form', {
    on: {
      "submit": [function ($event) {
        $event.preventDefault();
      }, _vm.saveData]
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipo de busqueda",
      "label-for": "vi-tipo"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-tipo",
      "options": _vm.tipos,
      "clearable": false
    },
    model: {
      value: _vm.tipo,
      callback: function callback($$v) {
        _vm.tipo = $$v;
      },
      expression: "tipo"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Busqueda",
      "label-for": "vi-inputbuscador"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-inputbuscador",
      "label": "datoCliente",
      "options": _vm.listadoUsuarios,
      "reduce": function reduce(u) {
        return u.idCliente;
      },
      "loading": _vm.loadingUsuarios
    },
    on: {
      "input": _vm.changeSelectedBusqueda
    },
    nativeOn: {
      "keyup": function keyup($event) {
        return _vm.onSearch($event);
      }
    },
    model: {
      value: _vm.inputbuscador,
      callback: function callback($$v) {
        _vm.inputbuscador = $$v;
      },
      expression: "inputbuscador"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cédula",
      "label-for": "vi-identificacion"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CreditCardIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-identificacion",
      "disabled": true,
      "placeholder": "Cédula"
    },
    model: {
      value: _vm.cliente.identificacion,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "identificacion", $$v);
      },
      expression: "cliente.identificacion"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cliente",
      "label-for": "vi-nombre"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-nombre",
      "disabled": true,
      "placeholder": "Cliente"
    },
    model: {
      value: _vm.cliente.nombreCompleto,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "nombreCompleto", $$v);
      },
      expression: "cliente.nombreCompleto"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Correo electronico",
      "label-for": "vi-emai"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MailIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-emai",
      "disabled": true,
      "placeholder": "Correo electronico"
    },
    model: {
      value: _vm.cliente.email,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "email", $$v);
      },
      expression: "cliente.email"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Telefono",
      "label-for": "vi-telefono"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SmartphoneIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-telefono",
      "disabled": true,
      "placeholder": "Telefono"
    },
    model: {
      value: _vm.cliente.telefono,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "telefono", $$v);
      },
      expression: "cliente.telefono"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Easy Créditos Disponibles",
      "label-for": "vi-easycredito"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-easycredito",
      "disabled": true,
      "placeholder": "Easy Créditos Disponibles"
    },
    model: {
      value: _vm.cliente.easycreditos,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "easycreditos", $$v);
      },
      expression: "cliente.easycreditos"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Número de orden",
      "label-for": "vi-orden"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ShoppingBagIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-orden",
      "disabled": !_vm.cliente.email,
      "placeholder": "Número de orden"
    },
    on: {
      "blur": _vm.getDataOrden
    },
    model: {
      value: _vm.orderId,
      callback: function callback($$v) {
        _vm.orderId = $$v;
      },
      expression: "orderId"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Valor total orden",
      "label-for": "vi-totalO"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-totalO",
      "disabled": true,
      "placeholder": "Valor total orden"
    },
    model: {
      value: _vm.orderTotal,
      callback: function callback($$v) {
        _vm.orderTotal = $$v;
      },
      expression: "orderTotal"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha de la orden",
      "label-for": "vi-fechaO"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "vi-fechaO",
      "min": _vm.minDate,
      "max": _vm.maxDate,
      "locale": "es",
      "disabled": true
    },
    model: {
      value: _vm.orderDate,
      callback: function callback($$v) {
        _vm.orderDate = $$v;
      },
      expression: "orderDate"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Total Easy Créditos otorgados a esta orden",
      "label-for": "vi-easyCreditosO"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-easyCreditosO",
      "disabled": true,
      "placeholder": "Easy Créditos de orden"
    },
    model: {
      value: _vm.orderEasyCreditos,
      callback: function callback($$v) {
        _vm.orderEasyCreditos = $$v;
      },
      expression: "orderEasyCreditos"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Easy Créditos máximos a dar a esta orden",
      "label-for": "vi-easyCreditosPA"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-easyCreditosPA",
      "disabled": true,
      "placeholder": "Easy Créditos Posibles"
    },
    model: {
      value: _vm.orderEasyCreditosPA,
      callback: function callback($$v) {
        _vm.orderEasyCreditosPA = $$v;
      },
      expression: "orderEasyCreditosPA"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Easy Creditos",
      "label-for": "vi-valor"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "DollarSignIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "id": "vi-valor",
      "max": "1000",
      "min": "0.01",
      "type": "number",
      "step": "0.01"
    },
    model: {
      value: _vm.cliente.valor,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "valor", $$v);
      },
      expression: "cliente.valor"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Descripción",
      "label-for": "vi-descripcion"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "vi-descripcion",
      "rows": "3",
      "placeholder": "Descripción"
    },
    model: {
      value: _vm.cliente.descripcion,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "descripcion", $$v);
      },
      expression: "cliente.descripcion"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha de expiración",
      "label-for": "vi-fecha"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    attrs: {
      "id": "vi-fecha",
      "min": _vm.minDate,
      "max": _vm.maxDate,
      "locale": "es"
    },
    model: {
      value: _vm.cliente.fechaVencimiento,
      callback: function callback($$v) {
        _vm.$set(_vm.cliente, "fechaVencimiento", $$v);
      },
      expression: "cliente.fechaVencimiento"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.loading
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }