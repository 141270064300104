<template>
  <b-alert v-if="error.length > 0" variant="danger" :show="true">
    <h4 class="alert-heading">Error obteniendo datos</h4>
    <div class="alert-body">{{ error }}</div>
  </b-alert>
  <b-row v-else>
    <b-col cols="12" md="12">
      <b-card-actions no-body noActions ref="refreshCard">
        <b-card-body>
          <h2 class="mb-2">Crear Easy Créditos</h2>
          <b-form @submit.prevent @submit="saveData">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Tipo de busqueda" label-for="vi-tipo">
                  <v-select
                    id="vi-tipo"
                    v-model="tipo"
                    :options="tipos"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group label="Busqueda" label-for="vi-inputbuscador">
                  <v-select
                    id="vi-inputbuscador"
                    label="datoCliente"
                    v-model="inputbuscador"
                    :options="listadoUsuarios"
                    :reduce="(u) => u.idCliente"
                    @keyup.native="onSearch"
                    @input="changeSelectedBusqueda"
                    :loading="loadingUsuarios"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group label="Cédula" label-for="vi-identificacion">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="CreditCardIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-identificacion"
                      v-model="cliente.identificacion"
                      :disabled="true"
                      placeholder="Cédula"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group label="Cliente" label-for="vi-nombre">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-nombre"
                      v-model="cliente.nombreCompleto"
                      :disabled="true"
                      placeholder="Cliente"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group label="Correo electronico" label-for="vi-emai">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-emai"
                      v-model="cliente.email"
                      :disabled="true"
                      placeholder="Correo electronico"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Telefono" label-for="vi-telefono">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SmartphoneIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-telefono"
                      v-model="cliente.telefono"
                      :disabled="true"
                      placeholder="Telefono"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Easy Créditos Disponibles"
                  label-for="vi-easycredito"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DollarSignIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-easycredito"
                      v-model="cliente.easycreditos"
                      :disabled="true"
                      placeholder="Easy Créditos Disponibles"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Número de orden" label-for="vi-orden">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="ShoppingBagIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-orden"
                      v-model="orderId"
                      :disabled="!cliente.email"
                      placeholder="Número de orden"
                      @blur="getDataOrden"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Valor total orden" label-for="vi-totalO">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DollarSignIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-totalO"
                      v-model="orderTotal"
                      :disabled="true"
                      placeholder="Valor total orden"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Fecha de la orden" label-for="vi-fechaO">
                  <b-input-group class="input-group-merge">
                    <b-form-datepicker
                      id="vi-fechaO"
                      :min="minDate"
                      :max="maxDate"
                      v-model="orderDate"
                      locale="es"
                      :disabled="true"
                    ></b-form-datepicker>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Total Easy Créditos otorgados a esta orden"
                  label-for="vi-easyCreditosO"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DollarSignIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-easyCreditosO"
                      v-model="orderEasyCreditos"
                      :disabled="true"
                      placeholder="Easy Créditos de orden"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  label="Easy Créditos máximos a dar a esta orden"
                  label-for="vi-easyCreditosPA"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DollarSignIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-easyCreditosPA"
                      v-model="orderEasyCreditosPA"
                      :disabled="true"
                      placeholder="Easy Créditos Posibles"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Easy Creditos" label-for="vi-valor">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="DollarSignIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-valor"
                      max="1000"
                      min="0.01"
                      type="number"
                      step="0.01"
                      v-model="cliente.valor"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Descripción" label-for="vi-descripcion">
                  <b-form-textarea
                    id="vi-descripcion"
                    rows="3"
                    v-model="cliente.descripcion"
                    placeholder="Descripción"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Fecha de expiración" label-for="vi-fecha">
                  <b-input-group class="input-group-merge">
                    <b-form-datepicker
                      id="vi-fecha"
                      :min="minDate"
                      :max="maxDate"
                      v-model="cliente.fechaVencimiento"
                      locale="es"
                    ></b-form-datepicker>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col cols="12" class="mt-2">
                <b-button
                  type="submit"
                  class="mr-1"
                  variant="primary"
                  :disabled="loading"
                >
                  <b-spinner v-if="loading" small label="Loading..." />
                  <div v-else>Guardar</div>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card-body></b-card-actions
      >
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BAlert,
  BButton,
  BSpinner,
  BCardBody,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormTimepicker,
  BFormDatepicker,
  BInputGroupPrepend,
} from "bootstrap-vue";

import vSelect from "vue-select";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserID, getUserFullName , getAuthToken} from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BAlert,
    BButton,
    vSelect,
    BSpinner,
    BCardBody,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardActions,
    BFormCheckbox,
    BFormTextarea,
    BFormTimepicker,
    BFormDatepicker,
    BInputGroupPrepend,
    ToastificationContent,
  },
  data() {
    return {
      tipo: "Email",
      tipos: ["Email", "Celular"],
      error: "",
      activo: false,
      cliente: {},
      minDate: this.getMinimunDate(),
      maxDate: this.getMaxDate(),
      loading: false,
      creacion: false,
      seachTimeout: null,
      inputbuscador: null,
      listadoUsuarios: [],
      loadingUsuarios: false,
      orderId: 0,
      orderTotal: 0,
      orderDate: null,
      orderEasyCreditos: 0,
      orderEasyCreditosPA: 0,
    };
  },
  methods: {
    searchUser(query) {
      this.loadingUsuarios = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/FiltroUsuario",
          body: JSON.stringify({
            Filtro: query,
            tokenSesion: getAuthToken(),
            TipoBusqueda: this.tipo === "Email" ? "1" : "2",
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.listadoUsuarios = res.data.lsUser;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error (${res.data.mensaje? res.data.mensaje: 'Error al consultar cliente'}).`,
                title: "Error",
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          this.error = e.message;
        })
        .finally(() => {
          this.loadingUsuarios = false;
        });
    },
    onSearch(query) {
      let textsearch = query.target.value;
      if (textsearch.length > 3) {
        clearTimeout(this.seachTimeout);
        this.seachTimeout = setTimeout(() => {
          this.searchUser(textsearch);
        }, 500);
      }
    },
    changeSelectedBusqueda(event) {
      if (event == null) this.resetClient();
      else this.getClientData(event);
    },
    getClientData(clientID) {
      this.$refs.refreshCard.showLoading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Customer/ConsultaUsuario",
          body: JSON.stringify({
            opt: "I",
            empresa: 2,
            cliente: clientID,
            tokenSesion: getAuthToken(),
            consultaEasyCreditos: true,
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            if (res.data.lstClientes.length > 0) {
              this.cliente = res.data.lstClientes[0];
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "AlertTriangleIcon",
                  text: `Ocurrio un error (No se obtuvo ningun cliente).`,
                  title: "Error",
                  variant: "danger",
                },
              });
            }
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.error = e.message;
        })
        .finally(() => {
          this.$refs.refreshCard.showLoading = false;
        });
    },
    saveData(event) {
      event.preventDefault();
      this.loading = true;
      if (
        Number(this.cliente.valor) <= Number(this.orderEasyCreditosPA) ||
        this.orderId === 0
      ) {
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Customer/CrearEasyCreditosMercado",
            body: JSON.stringify({
              Tipo: "Credito",
              ECMonto: this.cliente.valor,
              IDUsuario: this.cliente.id,
              ECDetalle: `Creado por ${getUserFullName()}`,
              CreadoPor: `${getUserID()}`,
              tokenSesion: getAuthToken(),
              ECDescripcion: this.cliente.descripcion,
              FechaVencimiento: this.cliente.fechaVencimiento,
              idOrden: this.orderId,
            }),
          })
          .then((res) => {
            this.loading = false;
            if (res.data.bOk) {
              this.resetClient();
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: res.data.mensaje,
                  title: "Exito",
                  variant: "success",
                },
              });
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error (${e.message}).`,
                title: "Error",
                variant: "danger",
              },
            });
          });
      } else {
        this.loading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: "AlertTriangleIcon",
            text: `Ocurrio un error (el valor de easycreditos a asignar debe ser menor o igual a los easycreditos posibles a asignar).`,
            title: "Error",
            variant: "danger",
          },
        });
      }
    },
    getMinimunDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const minDate = new Date(today);
      minDate.setFullYear(minDate.getFullYear() + 1);
      return minDate;
    },
    getMaxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const maxDate = new Date(today);
      maxDate.setFullYear(maxDate.getFullYear() + 3);
      return maxDate;
    },
    resetClient() {
      this.cliente = {};
      this.inputbuscador = null;
      (this.orderId = 0),
        (this.orderTotal = 0),
        (this.orderDate = null),
        (this.orderEasyCreditos = 0),
        (this.orderEasyCreditosPA = 0);
    },
    getDataOrden(event) {
      event.preventDefault();
      this.loading = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Order/ObtenerDatosOrden",
          body: JSON.stringify({
            IdOrden: this.orderId,
            IdCliente: this.cliente.id,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loading = false;
          if (res.data.bOk) {
            let data = res.data;
            this.orderTotal = data.totalOrden;
            this.orderDate = new Date(data.fechaCreacion);
            this.orderEasyCreditos = data.totalEasyCreditosAplicados;
            this.orderEasyCreditosPA = data.easyCreditosPosiblesAsignar;
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: `Ocurrio un error (${res.data.mensaje}).`,
                title: "Error",
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text: `Ocurrio un error (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
